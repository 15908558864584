import { useState, useMemo } from 'react';
import { ArrowDown2, ArrowUp2, Calendar, UserEdit } from 'iconsax-react';
import { Button } from '../../../../../../ds-components';
import ResultButtonGroup from '../../Buttons/ResultButtonGroup';
import FavorabilityModal from '../../Modals/FavorabilityModal';
import DecisionsTimeline from '../../Buttons/DecisionsTimeline';
import ReadMoreOrLessButton from '../../Buttons/ReadMoreOrLessButton';
import MoreInfoResultCard from './MoreInfoResultCard';
import AddTagDecision from '../../../../../collections/components/Collection/AddTagDecision';
import { ResultCardInteiroTeor } from './ResultCardInteiroTeor';
import { useLocation } from 'react-router-dom';
import { Badge } from './badge';

export const ResultCard = ({
  card,
  collectionId,
  query,
  monitoring,
  reloadExportStatus,
  theCollections,
  theMatchResultsThatHaveCollections,
}) => {
  const { search } = useLocation();

  const [showAllText, setShowAllText] = useState(false);
  const [openTimeline, setOpenTimeline] = useState(false);

  const hasFullText = useMemo(() => search.search('full_text') > -1, [search]);

  return (
    <div className={`tv-resultcard ${monitoring ? 'monitoring' : null}`}>
      <div className="gap-6 flex items-start">
        <Badge className="my-auto">{card.entity.name}</Badge>
        <div className="gap-2 flex flex-col">
          <h6 className="font-bold">{card.name}</h6>
          <div className="gap-6 flex">
            <div className="gap-2 flex items-center">
              {card.relator.name ? (
                <>
                  <UserEdit size="16" color="#84CAFF" />
                  <span className="text-sm leading-6 font-medium text-[#667085]">
                    Relator: {card.relator.name}
                  </span>
                </>
              ) : null}
            </div>
            <div className="gap-2 flex items-center">
              <Calendar size="16" color="#84CAFF" />
              <span className="text-xs font-normal leading-5 text-[#98A2B3]">
                {' '}
                {card.dataPub}
              </span>
            </div>
            <span className="text-xs font-normal leading-5 text-[#98A2B3]">
              {card.dataJul}
            </span>
          </div>
          <div>
            {card.chips !== null ? (
              card.chips.map((chip, index) => {
                return (
                  <div
                    key={chip + index}
                    className="sub-buttons"
                    style={{ position: 'relative' }}
                  >
                    <FavorabilityModal key={index} chip={chip} card={card} />
                    {collectionId ? null : (
                      <MoreInfoResultCard
                        card={card}
                        collectionId={collectionId}
                        context={null}
                        segment={'tributario'}
                      />
                    )}
                    {collectionId ? (
                      <AddTagDecision
                        card={card}
                        collectionId={collectionId}
                        reloadExportStatus={reloadExportStatus}
                      />
                    ) : null}
                  </div>
                );
              })
            ) : (
              <div className="sub-buttons" style={{ position: 'relative' }}>
                <FavorabilityModal card={card} />

                {collectionId ? null : (
                  <MoreInfoResultCard
                    card={card}
                    collectionId={collectionId}
                    context={null}
                    segment={'tributario'}
                  />
                )}
                {collectionId ? (
                  <AddTagDecision
                    card={card}
                    collectionId={collectionId}
                    reloadExportStatus={reloadExportStatus}
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
        <ResultButtonGroup
          card={card}
          collectionId={collectionId}
          query={query}
          theCollections={theCollections}
          theMatchResultsThatHaveCollections={
            theMatchResultsThatHaveCollections
          }
        />
      </div>
      <div className="tv-resultcard_content py-3">
        {card.content?.map((c, index) => {
          if (!showAllText) {
            if (c.title === 'Ementa') {
              return (
                <div className="pb-3" key={index}>
                  <h6 className="font-semibold">{c.title}</h6>
                  <p
                    dangerouslySetInnerHTML={{ __html: c.content }}
                    className="limited-text"
                  />
                </div>
              );
            }
            if (c.title === 'Inteiro Teor' && hasFullText) {
              return (
                <ResultCardInteiroTeor
                  key={card.id + 'inteiro_teor'}
                  content={c.content}
                  card={card}
                />
              );
            }
            return null;
          } else {
            if (c.title === 'Inteiro Teor' && hasFullText) {
              return (
                <ResultCardInteiroTeor
                  key={card.id + 'inteiro_teior'}
                  content={c.content}
                  card={card}
                />
              );
            }
            return (
              <div className="pb-3" key={index}>
                <h6 className="font-semibold">{c.title}</h6>
                <p dangerouslySetInnerHTML={{ __html: c.content }} />
              </div>
            );
          }
        })}
      </div>
      <div className="tv-resultcard_footer pt-4">
        {/*sera utilizado futuramente (Timeline) {card.timeline && card.timeline !== null ? (
          <Button
            appearance="secondary"
            size="sm"
            onClick={() => setOpenTimeline(!openTimeline)}
            icon={
              !openTimeline ? <ArrowDown2 size={12} /> : <ArrowUp2 size={12} />
            }
          >
            Outros acórdãos deste processo
          </Button>
        ) : null} */}

        <ReadMoreOrLessButton
          showAllText={showAllText}
          setShowAllText={setShowAllText}
          cardId={card.id}
        />
      </div>
      {/* <div>
        {openTimeline ? <DecisionsTimeline timeline={card.timeline} /> : null}
      </div> */}
    </div>
  );
};

export default ResultCard;
